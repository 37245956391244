export class MinimalMap {
    // Adapted from this:
    // https://www.d3-graph-gallery.com/graph/choropleth_basic.html

    constructor($elem, geoJson, geoData) {
        this.$elem = $elem;
        this.brand = false;

        this.geoJson = geoJson;
        this.geoData = geoData;
        this.color = this.getColorScale();

        this.draw();

        app.$elem.on('app:resized', () => {
            this.draw();
        });
    }

    hasData(id) {
        return this.geoData && id in this.geoData;
    }

    getCount(id) {
        if (this.hasData(id)) {
            return this.geoData[id].count;
        } else {
            return null;
        }
    }

    getColor(id) {
        if (this.hasData(id)) {
            return this.color(this.getCount(id));
        } else {
            return util.cssShade('off-mid');
        }
    }

    getColorScale() {
        if (this.brand) {
            const counts = Object.values(this.geoData).map(o => o.count);
            const domain = d3.extent(counts);
            return colorScale(domain);
        } else {
            const counts = Object.values(this.geoData).map(o => o.count);
            const countsMax = d3.max(counts);
            const colorRange = ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'];
            const valueScale = d3.scaleLog([1, 1 + countsMax], [0, 1]);
            const colorScale = d3.scaleQuantize([0, 1], colorRange);

            return n => colorScale(valueScale(1 + n));
        }
    }

    draw() {
        this.$elem.find('svg').remove();

        var w = this.$elem.width();
        var h = this.$elem.height();

        const background = this.brand ? '#f3f3f3' : '#e0e0e0';
        const strokeColor = this.brand ? '#f3f3f3': '#ffffff';
        const strokeWidth = this.brand ? 0.5 : 1;
        const fillOpacity = this.brand ? 1 : 0.8;

        this.$elem.css('background', background);

        const projection = d3.geoMercator()
            .fitSize([w, Math.max(275, h - 20)], this.geoJson)
            .center([0, 45])
            .translate([w/2, h/2]);

        const path = d3.geoPath()
            .projection(projection);

        const svg = d3.select(this.$elem.get(0))
            .append('svg')
            .attr('viewBox', [0, 0, w, h])
            .attr('height', h)
            .attr('width', w);

        svg.append('g')
            .selectAll('path')
            .data(this.geoJson.features)
            .join('path')
                .style('stroke', strokeColor)
                .style('stroke-width', strokeWidth)
                .style('fill-opacity', fillOpacity)
                .style('fill', d => this.getColor(d.id))
                .attr('d', path);
    }
}
