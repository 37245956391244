import { MinimalMap } from './helpers/minimal-map.js';
import { StatsTreePresentation } from './helpers/tree-map.js';
import { BubbleDiagram } from './helpers/bubble-diagram.js';


const configLookup = {
    'sinkhole': {
        'filters': {'source': ['sinkhole', 'sinkhole6']},
        'help': gettext("Sinkholing is a technique whereby a resource used by malicious actors to control malware is taken over and redirected to a benign listener that can (to a varying degree) understand network connections coming from infected devices. This provides visibility of the distribution of infected devices worldwide, as well as protecting victims by preventing botnet command and control (C2) from cybercriminals.")
    },
    'scan': {
        'filters': {'source': ['scan', 'scan6']},
        'help': gettext("Shadowserver scans the entire IPv4 Internet for over 100 different network protocols every day, and also performs IPv6 scans based on IPv6 hitlists for selected protocols. These are “hello” type port scans that do not exploit any vulnerability. They enable identification of misconfigured, vulnerable or abusable devices, unnecessarily exposed attack surfaces, or simply just population enumeration. Population enumeration results can be found under the “population” source type.")
    },
    'honeypot': {
        'filters': {'source': ['honeypot']},
        'help': gettext("Networks of honeypots can be used to observe hosts performing various server-side attacks, such as exploits targeting externally exposed services, or brute-forcing of credentials on IoT devices to obtain access via remote access protocols such as SSH, telnet, VNC, RDP and FTP. They can also be used to observe network scanning activity and amplification DDoS attempts.")
    },
    'ddos': {
        'filters': {'source': ['honeypot_ddos_amp']},
        'help': gettext("Observations from honeypots about reflected Distributed Denial of Service (DDoS) amplification events. This category of DDoS attacks utilizes UDP-based, publicly exposed, amplifiable network services to reflect packets to a victim, by spoofing the source IP address of the packets sent by the amplifier to the victim’s IP address.")
    },
    'ics-ot': {
        'filters': {'source': ['ics']},
        'help': gettext("Shadowserver scans the entire IPv4 address space for multiple native Industrial Control System / Operation Technology (ICS/OT) services and network protocols. The devices identified should probably not be exposed publicly on the Internet.")
    },
    'web-cves': {
        'filters': {'source': ['exchange', 'exchange6', 'http_vulnerable', 'http_vulnerable6'], 'tag': 'cve-*'},
        'help': gettext("Shadowserver scans for critical pre-auth Web-based remote code execution (RCE) vulnerabilities (or vulnerabilities that can be chained together by attackers to remotely execute code) in high-profile or otherwise popular software that is often exposed to the public Internet. These vulnerabilities, identified by their CVE entry are often exploited in the wild and should be remediated as quickly as possible.")
    }
};


$(() => {
    initDashboardMenu();
    initDashboardPanels();
    initTrendingQueries().then(sizeDashboardPanels);
    initLanguageSuggestion().then(sizeDashboardPanels);
});


function initLanguageSuggestion() {
    // Don't bother suggesting a language if a language has already
    // been selected (i.e. the translated path below has a prefix).
    if (app.getTranslatedPath('/') != '/') return Promise.resolve();

    return $.ajax({
        'url': '/language-suggestion/',
    }).done(responseData => {
        if (responseData) {
            $('.dashboard-panel-list').before(responseData);
        }
    });
}

function initTrendingQueries() {
    return $.ajax({
        'url': app.getTranslatedPath('/help/trending-queries/'),
        'data': {'banner': 1}
    }).done(responseData => {
        if (responseData) {
            $('.dashboard-panel-list').before(responseData);
        }
    });
}

function initDashboardMenu() {
    let $menu = $('.dashboard-menu');

    $menu.find('li').each((i, elem) => {
        const $item = $(elem);
        const $link = $item.find('a');
        $link.attr('href', '#' + $item.data('config-key'));
    });

    $menu.find('li').click(e => {
        e.preventDefault()
        let $item = $(e.currentTarget);
        if ($item.hasClass('active')) {
            $menu.toggleClass('open');
        } else {
            $menu.removeClass('open');
            loadDashboardPanels($item.data('config-key'));
        }
    });
}

function initDashboardPanels() {
    const key = location.hash.substr(1) || 'sinkhole';
    loadDashboardPanels(key, false);
}

function getStatsStatusDate() {
    try {
        return JSON.parse($('#stats-status-date').text());
    } catch (e) {
        // Date unavailable (i.e. on the maintenance page).
    }
}

function getDateFromYMD(ymd) {
    return new Date(Date.parse(ymd));
}

function getDateWithDaysDifference(d, days) {
    return new Date(new Date(d).setDate(d.getDate() + days));
}

function loadDashboardPanels(key, updateUrlFragment = true) {
    const $menuItems = $('.dashboard-menu li');
    const $panels = $('.dashboard-panel');
    const $help = $('.dashboard-help .help-content');

    const statsStatusDate = getStatsStatusDate();

    if (configLookup.hasOwnProperty(key)) {

        if (updateUrlFragment) {
            history.replaceState(null, null, '#' + key);
        }

        $menuItems.removeClass('active');
        $menuItems.filter(`[data-config-key="${key}"]`).addClass('active');

        const config = configLookup[key];

        // Need to set this first since it will affect the height
        // of the page on some screens, and therefore the height
        // of the panels that our content is rendered into.

        $help.html(config.help);

        new DashboardWorldMap($panels.eq(0), config.filters, statsStatusDate).load();
        new DashboardTreeMap($panels.eq(1), config.filters, statsStatusDate).load();
        new DashboardBubbleDiagram($panels.eq(2), config.filters, statsStatusDate).load();
        new DashboardTimeSeriesChart($panels.eq(3), config.filters, statsStatusDate).load();

    } else {
        console.error(`Unrecognised filter set: ${key}`);
    }
}

function sizeDashboardPanels() {
    // Have to do this in two steps (deleting all panels
    // before re-rendering them) in order to effectively
    // resize them, since the panels would just size
    // based on the tallest remaining content otherwise.
    $('.dashboard-panel').trigger('panel:delete');
    $('.dashboard-panel').trigger('panel:render');
}

class DashboardPanel {
    constructor($panel, filters, date) {
        this.$panel = $panel;
        this.$panelHeading = this.$panel.find('.panel-heading');
        this.$panelContent = this.$panel.find('.panel-content');

        this.filters = filters;
        this.date = date;

        this.$panel.on('panel:delete', () => this.delete());
        this.$panel.on('panel:render', () => {
            if (this.responseData) {
                this.render();
            }
        });
    }

    getRequestData() {
        throw new Error("Not implemented.");
    }

    setLinkHref(path, data) {
        const href = app.getTranslatedPath(path) + '?' + $.param(data, true);
        this.$panel.attr('href', href);
    }

    setHeading(data) {
        const $name = $('<span class="name">');
        const $date = $('<span class="date">');

        this.$panelHeading.empty();
        this.$panelHeading.append($name.text(this.name));
        this.$panelHeading.append($date.text(this.date));

        if (this.date && data.date_range && data.date_range > 1) {
            const isoFormat = d => d.toISOString().split('T')[0];
            const days = (data.date_range - 1);
            const d2 = getDateFromYMD(this.date);
            const d1 = getDateWithDaysDifference(d2, -days);

            // See django i18n docs for details on `interpolate` usage and parameters:
            // https://docs.djangoproject.com/en/4.2/topics/i18n/translation/#interpolate

            const dateRangeFmt = gettext('%(startDate)s <nobr>to %(endDate)s</nobr>');
            const dateRangeStr = interpolate(dateRangeFmt, {'startDate': isoFormat(d1), 'endDate': isoFormat(d2)}, true);

            $date.html(dateRangeStr);
        }
    }

    setLoading(toggle) {
        if (toggle) {
            this.$panelContent.addClass('panel-content-loading');
            this.$panelContent.html('<div class="loading-icon">');
        } else {
            this.$panelContent.removeClass('panel-content-loading');
            this.$panelContent.empty();
        }
    }

    setContent(responseData) {
        if (responseData.errors) {
            console.error(`Error loading ${this.constructor.name}:`, responseData.errors);
        } else {
            this.responseData = responseData;
            this.render();
        }
    }

    load() {
        let path = this.path;
        let data = this.getRequestData();

        // We need to do this before potentially modifying
        // the data object so the json param isn't included.
        this.setLinkHref(path, data);

        let source = this.$panel.data('source');
        if (source) {
            // Use cached path for maintenance page.
            path = source;
            data = {};
        } else {
            // Add JSON param for real landing page.
            data = {'json': 1, ...data};
        }

        this.setHeading(data);
        this.setLoading(true);

        $.ajax({
            url: path,
            type: 'GET',
            data: data,
            traditional: true
        }).done(responseData => {
            this.setLoading(false);
            this.setContent(responseData);
        }).fail(xhr => {
            console.error(util.xhr.errorMessage(xhr));
        });
    }

    delete() {
        this.$panelContent.empty();
    }

    render() {
        throw new Error("Not implemented.");
    }
}

class DashboardWorldMap extends DashboardPanel {
    name = gettext("Unique IP addresses per country");
    path = '/statistics/combined/map/';

    getRequestData() {
        return {
            'day': this.date,
            'source': this.filters.source,
            'tag': this.filters.tag || 'all',
            'map_type': 'std',
            'geo': 'all',
            'data_set': 'count'
        };
    }

    load() {
        this.geoJsonPath = '/static/js/statistics/world-countries/10m-simplified-to-110m.json';
        this.geoJsonPath = util.static.getPathFromManifest(this.geoJsonPath);
        d3.json(this.geoJsonPath).then(geoJson => {
            this.geoJson = geoJson;
            super.load();
        });
    }

    render() {
        const $map = $('<div class="minimal-map">');
        const data = this.responseData.geo_data;
        this.$panelContent.append($map);
        new MinimalMap($map, this.geoJson, data);
    }
}

class DashboardTreeMap extends DashboardPanel {
    name = gettext("Unique IP addresses per country");
    path = '/statistics/combined/tree/';

    getRequestData() {
        return {
            'day': this.date,
            'source': this.filters.source,
            'tag': this.filters.tag || 'all',
            'geo': 'all',
            'data_set': 'count'
        };
    }

    render() {
        const $tree = $('<div class="tree">');
        $tree.appendTo(this.$panelContent);
        const tree = new StatsTreePresentation($tree, {
            useClickableNodes: false,
            groupByContinent: false,
        });
        tree.show(this.responseData.result);
    }
}

class DashboardBubbleDiagram extends DashboardPanel {
    name = gettext("Unique IP addresses per tag");
    path = '/statistics/combined/visualisation/';

    getRequestData() {
        return {
            'source': this.filters.source,
            'tag': this.filters.tag || 'all',
            'date_range': 1,
            'dataset': 'unique_ips',
            'group_by': 'tag',
            'count_as': 'avg',
            'style': 'bubble_diagram'
        };
    }

    render() {
        new BubbleDiagram(this.$panelContent, this.responseData);
    }
}

class DashboardTimeSeriesChart extends DashboardPanel {
    name = gettext("Unique IP addresses over time");
    path = '/statistics/combined/time-series/';

    getRequestData() {
        // We're switching to a different set of filters for the "DDoS" source,
        // but it's new data which isn't back-filled, so we should stick with
        // the existing filters for the time-series chart until we reach a date
        // when we'll have 30 days of data available to avoid a chart with gaps.

        let source = this.filters.source;
        let tag = this.filters.tag || 'all';

        // Where `dateFirst` is the first day that will be shown in the chart and
        // `dateAvail` is the first day that has honeypot_ddos_amp data available.

        const days = 30;
        const dateStart = getDateWithDaysDifference(getDateFromYMD(this.date), -days);
        const dateAvail = getDateFromYMD('2022-09-19');

        if (source == 'honeypot_ddos_amp' && tag == 'all' && dateStart < dateAvail) {
            source = 'honeypot';
            tag = 'ddos-amplification';
        }

        return {
            'source': source,
            'tag': tag,
            'date_range': days,
            'dataset': 'unique_ips',
            'style': 'stacked'
        };
    }

    render() {
        let responseDataCopy = JSON.parse(JSON.stringify(this.responseData));
        let $chart = $('<div class="chart chart-manual">');
        $chart.appendTo(this.$panelContent);
        if (responseDataCopy.size) {
            delete responseDataCopy.size.height;
        }
        responseDataCopy.resize = {auto: false};
        responseDataCopy.shadowserver.resize_to_fit = true;
        responseDataCopy.axis.y.tick.format = 'units';
        responseDataCopy.interaction = {enabled: false};
        new Chart(app, $chart, responseDataCopy);
    }
}
