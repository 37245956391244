import { Loader } from './loader.js';
import { FrameQueue } from './frame-queue.js';
import { MapDetail } from './map-detail.js';
import { StatsFilters } from './stats-filters.js';
import { StatsDayNavigation } from './stats-day-navigation.js';
import * as urlparams from './urlparams.js';


// =====================================
// Statistics - Tree Map
// =====================================
// This is a D3 data visualisation rather than a geographical map, but it
// shares several UI concepts with the other maps. Particularly the
// filter form. It's more awkward to try and extract any non-map-specific
// UI code for reuse elsewhere than to just include this here for now.

export class StatsTree {
    constructor() {
        this.initProperties();
        this.initPresentation();
        this.initBehaviour();
    }

    initProperties() {
        this.groupByContinent = false;
        this.url = '/statistics/combined/tree/';
        this.detailUrl = app.getTranslatedPath('/statistics/combined/map/detail/{id}/');
        this.useClickableNodes = true;
        this.useUpdateUrlParams = true;
        this.presentationClass = StatsTreePresentation;

        if (app.isPageClass('statistics-iot-devices')) {
            this.replaceInUrls('/combined/', '/iot-devices/');
        } else if (app.isPageClass('statistics-honeypot-vulnerability')) {
            this.replaceInUrls('/combined/', '/honeypot/vulnerability/');
        } else if (app.isPageClass('statistics-honeypot-device')) {
            this.replaceInUrls('/combined/', '/honeypot/device/');
        }

        if ((new URL(location.href)).searchParams.get('group')) {
            this.groupByContinent = true;
        }

        this.$wrapper = $('.stats-wrapper');
        this.$sidebar = this.$wrapper.find('.stats-sidebar');
        this.$content = this.$wrapper.find('.stats-content');
        this.$filters = this.$sidebar.find('.map-filters');

        this.$elem = this.$content.find('.tree');
    }

    initPresentation() {
        const options = {
            useClickableNodes: this.useClickableNodes,
            groupByContinent: this.groupByContinent,
        };
        this.treemap = new this.presentationClass(this.$elem, options);
        this.treemap.$elem.on('openDialog', (event, d) => this.openDialog(d));
        this.loader = new Loader(this.$content);
    }

    initBehaviour() {
        this.initFilters();
        this.initDownload();
        this.loadData();
    }

    initFilters() {
        new StatsDayNavigation(this.$filters);
        this.filters = new StatsFilters(this.$filters);
        this.filters.$elem.find('#id_scale').closest('.form-row').hide();
        this.filters.$elem.on('filters:changed', () => {
            this.whenFiltersChanged();
        });
    }

    whenFiltersChanged() {
        if (this.useUpdateUrlParams) {
            urlparams.update(this.filters.data);
        }
        this.loadData();
    }

    initDownload() {
        this.$download = $('.stats-download');
        this.$download.hide();

        const $downloadBtn = this.$download.find('.download-as-png a');

        $downloadBtn.unbind('click').click(e => {
            e.preventDefault();

            if ($downloadBtn.hasClass('btn-disabled')) return;
            const $generating = util.loadingIndicatorBtn(gettext("Generating"));

            var queue = new FrameQueue();

            queue.add(() => {
                $downloadBtn.before($generating);
                $downloadBtn.hide();
            });

            queue.add(() => {
                var name = 'statistics-tree-map.png';
                util.downloadAsPng(this.$elem.get(0), name, () => {
                    $generating.remove();
                    $downloadBtn.show();
                }, true, true);
            });

            queue.run();
        });
    }

    loadData() {
        if (this.groupByContinent) {
            this.loadCountryLookup().then(this.loadTreeData.bind(this));
        } else {
            this.loadTreeData();
        }
    }

    loadCountryLookup() {
        const path = this.url;
        const data = {'json': 1, 'country_lookup': true};
        return this.loader.load(path, data, (responseData) => {
            if (responseData.lookup) {
                this.treemap.options.lookup = responseData.lookup;
            }
        }, 'GET');
    }

    loadTreeData() {
        const path = this.url;
        const data = {'json': 1, ...this.filters.data};
        return this.loader.load(path, data, (responseData) => {
            if (responseData.result) {
                this.showTreeData(responseData);
            } else if (responseData.errors) {
                this.showErrors(responseData.errors);
                this.showTreeData({});
            }
        }, 'GET');
    }

    showErrors(formErrors) {
        let $form = this.filters.$form;
        $form.find('.errorlist').remove();
        if (!$.isEmptyObject(formErrors)) {
            for (const [field, fieldErrors] of Object.entries(formErrors)) {
                if (fieldErrors.length == 1 && fieldErrors[0] == "This field is required.") continue;
                const $fieldOrForm = (field == '__all__') ? $form : $(`[name="${field}"]`);
                app.fieldErrors($fieldOrForm, fieldErrors);
            }
        }
    }

    showTreeData(data) {
        this.loader.withLoadingText(
            gettext("Rendering"),
            () => {
                this.treemap.show(data);
                this.$download.show();
            }
        );
    }

    openDialog(d) {
        var path = this.detailUrl.replace('{id}', d.data.id);
        var data = this.filters.data;
        this.loadDialogContent(path, data);
    }

    loadDialogContent(url, data) {
        var $dialog = $('<div>').html('<div class="loading-icon"></div>');

        $dialog.dialog(util.dialogConfig({
            classes: {'ui-dialog': 'no-title'},
            width: 450
        }));

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            traditional: true
        }).done(content => {
            $dialog.html(content);
            $dialog.find('.map-detail').width('100%');
            new MapDetail($dialog);
            $dialog.dialog('option', 'position', {
                my: 'center',
                at: 'center',
                of: window
            });
        }).fail(xhr => {
            if (xhr.statusText == 'abort') return;
            $dialog.html(util.xhr.errorMessage(xhr));
        });
    }

    replaceInUrls(pattern, replace) {
        for (let propName of ['url', 'detailUrl']) {
            this[propName] = this[propName].replace(pattern, replace);
        }
    }
}

export class StatsTreePresentation {
    constructor($elem, options) {
        this.$elem = $elem;
        this.brand = false;
        this.options = options;
        util.handleResize($elem.get(0), () => {
            this.draw();
        });
    }

    getContinent(cc) {
        if (this.options.lookup && this.options.lookup[cc]) {
            return this.options.lookup[cc].continent;
        } else {
            return '??';
        }
    }

    getTreeData(counts) {
        // Convert counts into the form expected by d3.hierarchy().

        // The original data from the server might contain supporting
        // properties aside from the counts themselves. When that's
        // the case, the counts will be under the `result` property:
        if (counts && counts.result) {
            counts = counts.result;
        }

        var children = [];
        var childrenGrouped = {};
        var countMin = null;
        var countMax = null;

        for (let [key, val] of Object.entries(counts)) {
            if (!val.count) continue;

            var child = this.getTreeDataChild(key, val);
            var group = this.getTreeDataGroup(key, val);

            if (countMin === null || val.count < countMin) countMin = val.count;
            if (countMax === null || val.count > countMax) countMax = val.count;

            if (group) {
                if (childrenGrouped.hasOwnProperty(group) == false) {
                    childrenGrouped[group] = [];
                }
                childrenGrouped[group].push(child);
            } else {
                children.push(child);
            }
        }

        if (!$.isEmptyObject(childrenGrouped)) {
            children = Object.entries(childrenGrouped).map(([key, val]) => {
                return {'label': key, 'children': val};
            });
        }

        return {'root': {'children': children}, 'min': countMin, 'max': countMax};
    }

    getTreeDataChild(k, v) {
        return {'id': k, 'label': v.label, 'value': v.count};
    }

    getTreeDataGroup(k, v) {
        if (this.options.groupByContinent) {
            return this.getContinent(k);
        }
    }

    getDomain() {
        if (this.brand) {
            return [this.modifiedData.min, this.modifiedData.max];
        } else {
            return [0, this.modifiedData.max];
        }
    }

    getColorScale() {
        if (this.brand) {
            return colorScale(this.getDomain());
        } else {
            const range = [util.cssColor('range-start'), util.cssColor('range-end')];
            return d3.scalePow(this.getDomain(), range).exponent(0.4);
        }
    }

    getValueScale() {
        return d3.scalePow(this.getDomain(), [0, 1]).exponent(0.4);
    }

    show(data) {
        // If .show() is called with no args we want to re-evaluate
        // the last data we received in the context of new options.
        //
        // Ordinarily when redrawing the tree-map we could just call
        // .draw(), but some options change how the data is modified
        // in .getTreeData() so we need to call this method instead.

        this.originalData = data || this.originalData;
        this.modifiedData = this.getTreeData(this.originalData);

        this.scale = this.getValueScale();
        this.color = this.getColorScale();

        this.draw();
    }

    draw() {
        // Get height and width of container (without currently drawn tree expanding it)
        this.$elem.find('svg').remove();
        const height = this.$elem.height();
        const width = this.$elem.width();

        const data = this.modifiedData.root;
        const root = d3.hierarchy(data);
        root.sum(d => Math.max(0, d.value));

        if (data.children.length == 0) {
            console.warn("Tree map data is empty.");
            return;
        }

        // Sort the leaves (typically by descending value for a pleasing layout).
        root.sort((a, b) => b.value - a.value);

        const leaves = root.leaves();

        // Compute the treemap layout.
        d3.treemap()
            .tile(d3.treemapSquarify)
            .size([width, height])
            .padding(1)
            .round(true)
          (root);

        const svg = d3.create("svg")
            .attr("viewBox", [0, 0, width, height])
            .attr("width", width)
            .attr("height", height)
            .attr("style", "display: block; max-width: 100%; height: auto; height: intrinsic; direction: ltr;")
            .attr("font-family", util.cssCustomProperty("brand-font-family"))
            .attr("font-size", 10);

        // Do this before calling drawSpans() as the node needs to be
        // in the DOM for nodeLabelTruncate() to calculate text widths.
        this.$elem.html(svg.node());

        const nodes = this.drawNodes(svg, leaves, width);
        const group = this.drawGroup(nodes, width, height);
        const spans = this.drawSpans(group);
    }

    drawNodes(svg, leaves, width) {
        let nodeTransform;

        // Can't find any official support for RTL layout in the d3 treemap docs,
        // but this is a simple enough way to horizontally mirror the layout of
        // the nodes, while still rendering the text from left-to-right.

        if (this.$elem.css('direction') == 'rtl') {
            nodeTransform = d => `translate(${(width - d.x1)},${d.y0})`;
        } else {
            nodeTransform = d => `translate(${d.x0},${d.y0})`;
        }

        const nodes = svg.selectAll("g")
             .data(leaves)
             .join("g")
             .attr("transform", nodeTransform);

        if (this.options.useClickableNodes) {
            nodes.style('cursor', this.nodePointerCursor.bind(this));
            nodes.on('click', this.nodeClick.bind(this));
        }

        nodes.append("defs")
            .append("clipPath")
            .attr("id", (d, i) => d.data.id)
            .append("rect")
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        nodes.append("rect")
            .attr("fill", (d, i) => this.nodeBackground(d))
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        nodes.append('title').text((d, i) => this.nodeTitle(d));

        return nodes;
    }

    drawGroup(nodes, width, height) {
        const fontSize = Math.floor(0.05 * Math.sqrt(width * height));

        const group = nodes
            .append('g')
            .attr('clip-path', d => `url(#${d.data.id})`)
            .append('text')
            .attr("transform", d => `translate(${Math.round((d.x1 - d.x0) / 2)}, ${Math.round((d.y1 - d.y0) / 2)})`)
            .style('font-size', d => `${Math.round(fontSize * this.scale(d.data.value))}px`);

        return group;
    }

    drawSpans(group) {
        this.drawSpansForLabelValue(group);
    }

    drawSpansForLabelValue(group) {
        group.append('tspan')
            .attr('x', 0)
            .attr('y', '-0.2em')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('fill', 'white')
            .style('font-size', '1.0em')
            .text(d => d.data.label)
            .each(this.nodeLabelTruncate);

        group.append('tspan')
            .attr('x', 0)
            .attr('y', '1.2em')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('fill', 'rgba(255, 255, 255, 0.66)')
            .style('font-size', '0.7em')
            .text(d => this.drawSpansValue(d))
            .each(this.nodeLabelTruncate);
    }

    drawSpansValue(d) {
        return util.number.units(d.data.value, 1, {fixed: false});
    }

    nodePointerCursor(d) {
        return 'pointer';
    }

    nodeDefaultCursor(d) {
        return 'default';
    }

    nodeClick(ev, d) {
        this.$elem.trigger('openDialog', [d]);
    }

    nodeTitle(d) {
        let labelTop = this.nodeLabelTop(d);
        let labelMid = this.nodeLabelMid(d);
        let count = d.value !== undefined ? util.number.commas(d.value): 0;
        return `${labelTop}: ${labelMid}: ${count}`;
    }

    nodeLabelTop(d) {
        return (d.data.label && d.data.label.top) || d.data.id;
    }

    nodeLabelMid(d) {
        return (d.data.label && d.data.label.mid) || d.data.label;
    }

    nodeLabelTruncate(d) {
        // Adapted from: https://stackoverflow.com/a/27723752
        const dx = d.x1 - d.x0;
        if (!d.data.id || dx < 10) return;

        var self = d3.select(this);
        var text = self.text();

        var getTextLength = () => Math.floor(self.node().getComputedTextLength());
        var maxTextLength = (dx * 0.9);

        while (text && getTextLength() > maxTextLength) {
            text = text.slice(0, -1).trimEnd();
            self.text(text + '…');
        }
    }

    nodeBackground(d) {
        if (this.options.groupByContinent) {
            var color = {
                'AS': util.cssColor('red'),
                'SA': util.cssColor('orange'),
                'AF': util.cssColor('yellow'),
                'EU': util.cssColor('green'),
                'NA': util.cssColor('blue'),
                'OC': util.cssColor('magenta'),
                'AN': '#aaaaaa',
                '??': '#aaaaaa',
            }
            if (d.depth == 2) {
                return this.scale.copy().range([
                    d3.rgb('#000000'),
                    d3.rgb(color[d.parent.data.label])
                ])(d.value);
            }
        } else {
            if (d.depth == 1) {
                return this.color(d.value);
            }
        }

        return '#ffffff';
    }

}
