import { FrameQueue } from './frame-queue.js';

export class BubbleDiagram {
    constructor($elem, data, loader) {
        this.$elem = $elem;
        this.brand = false;
        this.loader = loader;

        this.valueTot = 0;
        this.valueMax = 0;
        this.valueMin = Infinity;

        this.words = [];
        $.each(data.words, (key, val) => {
            var id = key.toLowerCase().replace(/\W+/g, '-');
            this.valueTot += val;
            if (val > this.valueMax) this.valueMax = val;
            if (val < this.valueMin) this.valueMin = val;
            this.words.push({ id: id, label: key, value: val });
        });

        if (this.words.length == 0) {
            console.error("Unable to render bubble diagram. No data was provided.");
            return;
        }

        this.drawWithLoader();

        util.handleResize(this.$elem.get(0), () => {
            this.drawWithLoader();
        });
    }

    getDomain() {
         if (this.brand) {
            return [this.valueMin, this.valueMax];
         } else {
            return [0, this.valueMax];
         }
    }

    getColorScale() {
         if (this.brand) {
            return colorScale(this.getDomain());
         } else {
            const range = [util.cssColor('range-start'), util.cssColor('range-end')];
            return d3.scalePow(this.getDomain(), range).exponent(0.4);
         }
    }

    drawWithLoader() {
        if (this.loader) {
            this.loader.withLoadingText(
                gettext("Rendering"),
                () => this.draw()
            );
        } else {
            this.draw();
        }
    }

    draw() {
        // See: https://observablehq.com/@d3/bubble-chart

        this.$elem.find('svg').remove();

        const w = this.$elem.width();
        const h = this.$elem.height();

        const data = this.words;

        // Compute the values.
        const V = d3.map(data, d => d.value); // Lookup mapping d.data to the original value
        const L = d3.map(data, d => d.label); // Lookup mapping d.data to the original label
        const I = d3.range(V.length).filter(i => V[i] > 0); // The indices of datums with non-zero values

        // Construct color scale.
        const color = this.getColorScale();

        // Compute layout: create a 1-deep hierarchy, and pack it.
        const tree = d3.hierarchy({children: I}).sum(i => V[i]);
        const root = d3.pack().size([w, h]).padding(3)(tree);

        // Helper functions for line-wrapped text in tspans.

        const lineIsLabel = (i, D) => {
            // Differentiate between the label lines
            // and the last line which is a number.
            return i != D.length - 1;
        }

        const linePosition = (d, i, D) => {
            let scale = lineIsLabel(i, D) ? 1 : (1 / 0.7);
            let lineOrigin = scale * 1;
            let lineHeight = scale * 1.1;
            return `${lineOrigin + (lineHeight * (i - (D.length / 2)))}em`;
        };

        const lineFill = (d, i, D) => {
            return lineIsLabel(i, D) ? 'inherit' : 'rgba(255, 255, 255, 0.66)';
        };

        const lineFontSize = (d, i, D) => {
            return lineIsLabel(i, D) ? 'inherit' : '0.7em';
        };

        // Mirror layout of nodes for RTL languages (but still
        // render English language text from left-to-right).

        let nodeTransform;
        if (this.$elem.css('direction') == 'rtl') {
            nodeTransform = d => `translate(${w - d.x},${d.y})`;
        } else {
            nodeTransform = d => `translate(${d.x},${d.y})`;
        }

        // Draw the bubble diagram.

        const svg = d3.select(this.$elem.get(0))
            .append('svg')
            .attr('viewBox', [0, 0, w, h])
            .attr('width', w)
            .attr('height', h)
            .attr('style', 'display: block; max-width: 100%; height: auto; height: intrinsic; direction: ltr;')
            .attr('fill', 'white')
            .attr('font-family', util.cssCustomProperty('brand-font-family'))
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle');

        const node = svg
            .selectAll('g')
            .data(root.leaves())
            .join('g')
            .attr('transform', nodeTransform);

        node.append('circle')
            .attr('r', d => d.r)
            .attr('fill', d => color(d.value));

        node.append('title')
            .text(d => `${L[d.data]}: ${V[d.data].toLocaleString('en')}`);

        node.append('clipPath')
            .attr('id', d => `clip-${d.data}`)
            .append('circle')
            .attr('r', d => d.r);

        const text = node.append('text')
            .attr('clip-path', d => `url(#clip-${d.data})`)
            .style('font-size', d => `${Math.round(d.r * 0.25)}px`);

        text.selectAll('tspan')
            .data(d => {
                const label = L[d.data];
                const value = V[d.data];
                return this.labelWrap(label, value).map(line => {
                    return {'r': d.r, 'line': line};
                });
            })
            .join('tspan')
                .attr('x', 0)
                .attr('y', linePosition)
                .style('fill', lineFill)
                .style('font-size', lineFontSize)
                .text(d => d.line)
                .each(this.labelTruncate);
    }

    labelTruncate(d) {
        // Truncates node labels. Adapted from:
        // https://stackoverflow.com/a/27723752
        let dw = (d.r * 2);
        if (dw < 10) return;

        let self = d3.select(this);
        let text = self.text();

        const getTextLength = () => Math.floor(self.node().getComputedTextLength());
        const maxTextLength = (dw * 0.9);

        while (text && getTextLength() > maxTextLength) {
            text = text.slice(0, -1).trimEnd();
            self.text(text + '…');
        }
    }

    labelWrap(label, value) {
        // Wrap labels on whitespace and punctuation characters by default,
        // but use a negative look-ahead to try to avoid wrapping when it
        // might leave a short or empty string alone on a line (see tags
        // from the "blocklist" source for examples of this).

        const basicRE = /[\W_.-]+/;

        const aheadRE = new RegExp('(?![a-z0-9]{0,3}(?:' + basicRE.source + '|$))');
        const splitRE = new RegExp(basicRE.source + aheadRE.source, 'gi');
        const stripRE = new RegExp('^[^a-z0-9]+|[^a-z0-9]+$', 'gi');

        let lines = [];
        let count = util.number.units(value, 1, {fixed: false});

        if (label.match(/^cve-\d{4}-\d+$/)) {
            // Don't wrap CVE IDs (see tags for "exchange" source).
            lines = [label];
        } else if (label.includes(';')) {
            // Wrap on semi-colons but not on whitespace or
            // punctuation (see tags for "exchange", "http_vulnerable"
            // and "smtp_vulnerable" sources).
            lines = label.split(';');
        } else {
            // Wrap on whitespace and punctuation.
            lines = label.replace(stripRE, '').split(splitRE);
        }

        // Include formatted count as last line.
        lines = lines.concat(count);

        return lines;
    }
}
